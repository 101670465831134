<template>
  <div style="display: flex; cursor: pointer" @click="sortTable">
    <span>
      {{ i18n.t(`editWalletView.${name}`) }}
    </span>
    <span style="margin-left: 10px">
      <font-awesome-icon :icon="faSort" size="1x" class="fa-icon" v-if="sortData?.value !== name" />
      <div v-else>
        <font-awesome-icon :icon="faSortUp" size="1x" class="fa-icon" v-if="sortData?.order === 'asc'" />
        <font-awesome-icon :icon="faSortDown" class="fa-icon" size="1x" v-else />
      </div>
    </span>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'TableCustomTitle',
  props: {
    name: {
      type: String,
      required: true,
    },
    sortData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = reactive(useI18n());

    const sortTable = () => {
      emit('sortTable', props.name);
    };

    return { i18n, faSort, faSortUp, faSortDown, sortTable };
  },
};
</script>

<style></style>
